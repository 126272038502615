const colorMappings = [
  '#1c64ff',
  '#02c9a1',
  '#1C3FFF',
  '#26cce2',
  '#b555fa',
  '#e34646',
  '#fc6a2c',
  '#00986C',
  '#b555fa',
  '#009BB0',
];

export default colorMappings;
